import {
    ChangeDetectionStrategy,
    Component,
    HostBinding,
    Input
} from '@angular/core';
import { NgOptimizedImage } from '@angular/common';
import { A11tPosition } from './accessibility-icon.component.consts';
import { environment } from '@env';

@Component({
    selector: 'app-accessibility-icon',
    standalone: true,
    imports: [NgOptimizedImage],
    templateUrl: './accessibility-icon.component.html',
    styleUrls: ['./accessibility-icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccessibilityIconComponent {
    @HostBinding('class') @Input() position: A11tPosition =
        A11tPosition.START_BOTTOM;

    iconSrc = `${environment.ASSETS_BASE_URL}/assets/icons/accessibility-body.svg`;
}
