import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as AuthActions from '../../../auth/store/auth.actions';
import { USER_KEY } from '../../../auth/constants/auth.constants';
import { tap, withLatestFrom } from 'rxjs/operators';
import * as ProfileActions from '../../../profile/store/profile.actions';
import { AppState } from '../app.reducers';
import { Store } from '@ngrx/store';
import * as UserSelectors from '../../store/selectors/user.selectors';

@Injectable()
export class UserEffects {
    loginEffect = createEffect(
        () =>
            this.actions$.pipe(
                ofType(
                    AuthActions.login,
                    ProfileActions.updateSettings,
                    ProfileActions.updatePicUrl,
                    ProfileActions.updateDetails,
                    ProfileActions.removePendingMediaTrek
                ),
                withLatestFrom(this.store.select(UserSelectors.getUser)),
                tap(([action, user]) =>
                    localStorage.setItem(USER_KEY, JSON.stringify(user))
                )
            ),
        { dispatch: false }
    );

    constructor(private actions$: Actions, private store: Store<AppState>) {}
}
