import { environment } from '@env';
import { LoggedInUser } from '../../../auth/models/auth.model';
import * as AuthActions from '../../../auth/store/auth.actions';
import { Action, createReducer, on } from '@ngrx/store';
import * as ProfileActions from '../../../profile/store/profile.actions';

export interface UserState {
    user: LoggedInUser | null;
    profileUrl: string;
}

export const initialState: UserState = {
    user: null,
    profileUrl: ''
};

// @ts-ignore
const userReducer = createReducer(
    initialState,
    on(AuthActions.login, (state, { user }) => ({
        ...state,
        user,
        profileUrl: `${environment.MEDIA_URL}/profile_picture/${user.id}/small`
    })),
    on(AuthActions.logout, (state) => ({
        ...state,
        user: null,
        profileUrl: ''
    })),
    on(ProfileActions.updatePicUrl, (state, { url }) => ({
        ...state,
        profileUrl: url
    })),
    on(ProfileActions.updateDetails, (state, { name, tag }) => ({
        ...state,
        user: {
            ...state.user!,
            name,
            tag
        }
    })),
    on(
        ProfileActions.updateSettings, // @ts-ignore TODO - fix type
        (state, { locale, allowFollowing }) => {
            const localeToUpdate = locale === 'iw' ? 'he' : locale;
            return {
                ...state,
                user: {
                    ...state.user,
                    settings: {
                        locale: localeToUpdate,
                        allow_following: allowFollowing
                    }
                }
            };
        }
    ),
    on(ProfileActions.removePendingMediaTrek, (state, { trek_id }) => ({
        ...state,
        user: {
            ...state.user!,
            waiting_pending_media: state.user!.waiting_pending_media!.filter(
                (trek) => trek.trek_id !== trek_id
            )
        }
    }))
);

export function UserReducer(state?: UserState, action?: Action) {
    return userReducer(state, action!);
}
