import { Injectable } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import { concatMap, tap } from 'rxjs/operators';
import { AppStoreService } from '../app-store.service';

@Injectable()
export class AppStoreEffect {
    saveSyncStoreEffect = createEffect(
        () =>
            this.actions$.pipe(
                concatMap(() =>
                    this.appStoreService.store.select((appState) => appState)
                ),
                tap((appState) => {
                    this.appStoreService.setSyncState(appState);
                })
            ),
        {
            dispatch: false
        }
    );

    constructor(
        private appStoreService: AppStoreService,
        private actions$: Actions
    ) {}
}
