<span
    [matMenuTriggerFor]="searchResMenu"
    #trigger="matMenuTrigger"
    [dir]="direction"
    ><app-base-search-input
        class="search-input"
        ngDefaultControl
        (onClickedEnter)="clickEnterInSearchHandler($event)"
        [formControl]="searchCtrl"
        (onFocused)="
            searchCtrl.value.length > 2 && trigger.openMenu()
        "></app-base-search-input
></span>
<mat-menu
    [xPosition]="isScreen768 ? 'before' : 'after'"
    #searchResMenu
    class="search-menu">
    <app-search-result
        [searchKey$]="searchKey$"
        (onGetResult)="onGetResult()"
        (onSelectSeeMore)="seeMoreClicked($event)"
        (onSelectUrlNavigate)="clickLink($event)"></app-search-result>
</mat-menu>
