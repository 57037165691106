import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap, withLatestFrom } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { WISHLIST_KEY } from '../../auth/constants/auth.constants';
import * as WishListSelectors from './wish-list.selectors';
import { AppState } from '../../app/store/app.reducers';
import * as WishListActions from './wish-list.actions';

@Injectable()
export class WishListEffects {
    wishlistEffect = createEffect(
        () =>
            this.actions$.pipe(
                ofType(
                    WishListActions.setWishlist,
                    WishListActions.addSite,
                    WishListActions.addTrek,
                    WishListActions.removeSite,
                    WishListActions.removeTrek
                ),
                withLatestFrom(
                    this.store.select(WishListSelectors.getWishList)
                ),
                tap(([action, wishlist]) =>
                    localStorage.setItem(WISHLIST_KEY, JSON.stringify(wishlist))
                )
            ),
        { dispatch: false }
    );

    constructor(private actions$: Actions, private store: Store<AppState>) {}
}
