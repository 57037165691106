import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { UserReducer, UserState } from './reducers/user.reducer';
import { SearchState } from './app-store.model';
import { UIReducer, UIState } from './reducers/ui.reducer';
import {
    WishListReducer,
    WishListState
} from '../../wish-list/store/wish-list.reducer';
import { SiteReducer, SiteState } from './reducers/site.reducer';
import { SearchReducer } from '../../search/store/search.reducer';

export interface AppState {
    userState: UserState;
    siteState: SiteState;
    searchState: SearchState;
    uIState: UIState;
    wishListState: WishListState;
}

export const reducers: ActionReducerMap<AppState> = {
    userState: UserReducer,
    siteState: SiteReducer,
    searchState: SearchReducer,
    uIState: UIReducer,
    wishListState: WishListReducer
};

export const metaReducers: MetaReducer<AppState>[] = [];
