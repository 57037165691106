import {
    ChangeDetectionStrategy,
    Component,
    inject,
    OnInit,
    ViewChild
} from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { TypographyDirective } from '../../../../atoms/directives/typography/typography.directive';
import { AppRoutesPaths } from '../../../app-routes';
import * as SearchActions from '../../../../search/store/search.actions';
import { Router } from '@angular/router';
import { debounceTime, filter, startWith, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AsyncPipe, NgOptimizedImage } from '@angular/common';
import { environment } from '@env';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { BaseSearchInputComponent } from '../../../../atoms/components/inputs/base-search-input/base-search-input.component';
import { IS_SCREEN_768 } from '../../../constants/app.constants';
import { AppStoreService } from '../../../store/app-store.service';
import { SearchResultComponent } from '../../../../atoms/components/search-result/search-result.component';
import { Direction } from '@angular/cdk/bidi';
import { RTL_LOCALES } from '../../../constants/locales.constants';

@Component({
    selector: 'app-header-search-input',
    standalone: true,
    imports: [
        TranslateModule,
        TypographyDirective,
        AsyncPipe,
        MatMenuModule,
        NgOptimizedImage,
        ReactiveFormsModule,
        BaseSearchInputComponent,
        SearchResultComponent
    ],
    templateUrl: './header-search-input.component.html',
    styleUrl: './header-search-input.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderSearchInputComponent implements OnInit {
    searchCtrl = new FormControl<string>('', { nonNullable: true });
    mediaUrl = environment.MEDIA_URL;
    @ViewChild(MatMenuTrigger) searchResMenuTrigger?: MatMenuTrigger;
    isScreen768 = inject(IS_SCREEN_768);
    disableAutocompletePanel = false;
    searchKey$!: Observable<string>;
    direction: Direction = RTL_LOCALES.includes(this.translate.currentLang)
        ? 'rtl'
        : 'ltr';

    constructor(
        private router: Router,
        private appStoreService: AppStoreService,
        private translate: TranslateService
    ) {}

    ngOnInit() {
        this.searchKey$ = this.searchCtrl.valueChanges.pipe(
            debounceTime(250),
            startWith(''),
            tap((term) => {
                if (term.length <= 2) {
                    this.searchResMenuTrigger?.closeMenu();
                }
            }),
            filter((term) => term.length > 2)
        );
    }

    onGetResult() {
        if (!this.disableAutocompletePanel) {
            this.searchResMenuTrigger?.openMenu();
        }
        this.disableAutocompletePanel = false;
    }

    public seeMoreClicked(tabIndex: number): void {
        if (this.searchCtrl.value) {
            this.appStoreService.store.dispatch(
                SearchActions.syncQuery({ query: this.searchCtrl.value })
            );
            this.searchResMenuTrigger?.closeMenu();
            this.router.navigate([
                `${AppRoutesPaths.SEARCH}`,
                this.searchCtrl.value,
                tabIndex
            ]);
            this.appStoreService.store.dispatch(
                SearchActions.updateSelectedTab({ tab_num: tabIndex })
            );
        }
    }

    clickEnterInSearchHandler(event: Event) {
        event.preventDefault();
        this.disableAutocompletePanel = true;
        this.searchResMenuTrigger?.closeMenu();
        this.seeMoreClicked(this.appStoreService.state.searchState.tab || 0);
    }

    clickLink(url: string) {
        this.searchResMenuTrigger?.closeMenu();
        this.router.navigateByUrl(url);
    }
}
