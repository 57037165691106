import {
    Component,
    DestroyRef,
    inject,
    OnInit,
    ViewChild
} from '@angular/core';
import { Observable, shareReplay } from 'rxjs';
import { LoggedInUser } from '../../../auth/models/auth.model';
import { Router, RouterLink } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '../../store/app.reducers';
import { selectUserStateFeature } from '../../store/selectors/user.selectors';
import * as SiteSelectors from '../../store/selectors/site.selectors';
import { RTL_LOCALES } from '../../constants/locales.constants';
import { first, map, tap } from 'rxjs/operators';
import { MatSidenav } from '@angular/material/sidenav';
import { LoginDialogService } from '../../../auth/services/login-dialog.service';
import { environment } from '@env';
import { WwbModeService } from '../../services/wwb-mode.service';
import { AppRoutesPaths } from '../../app-routes';
import { HomeRoutesPaths } from '../../../home/home.routes';
import { IS_SCREEN_768 } from '../../constants/app.constants';
import { AsyncPipe, NgOptimizedImage } from '@angular/common';
import { HeaderSearchInputComponent } from './header-search-input/header-search-input.component';
import { HeaderLinksComponent } from './header-links/header-links.component';
import { HeaderUserLinksComponent } from './header-user-links/header-user-links.component';
import { SidenavComponent } from './sidenav/sidenav.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
    selector: 'app-header',
    standalone: true,
    templateUrl: 'header.component.html',
    imports: [
        RouterLink,
        NgOptimizedImage,
        HeaderSearchInputComponent,
        HeaderLinksComponent,
        HeaderUserLinksComponent,
        AsyncPipe,
        SidenavComponent
    ],
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
    readonly AppRoutesPaths = AppRoutesPaths;
    readonly HomeRoutesPaths = HomeRoutesPaths;
    isScreen768 = inject(IS_SCREEN_768);
    imgLogo = `${environment.ASSETS_BASE_URL}/assets/images/${
        this.isScreen768 ? 'logo-small-white' : 'logo'
    }.svg`;
    isRtl$!: Observable<boolean>;
    user!: LoggedInUser | null;
    userState$ = this.store.select(selectUserStateFeature);
    profileUrl!: string;
    @ViewChild('sidenav', { static: false }) sidenav!: MatSidenav;
    destroyRef = inject(DestroyRef);
    isWwbMode = inject(WwbModeService).isWwbMode;

    constructor(
        private store: Store<AppState>,
        private router: Router,
        private loginDialogService: LoginDialogService
    ) {}

    ngOnInit() {
        this.userState$ = this.store.select(selectUserStateFeature).pipe(
            takeUntilDestroyed(this.destroyRef),
            tap((userState) => {
                this.user = userState.user;
                this.profileUrl = userState.profileUrl;
            }),
            shareReplay()
        );

        this.isRtl$ = this.store.select(SiteSelectors.getSiteLanguage).pipe(
            first((lang) => !!lang),
            map((lang) => RTL_LOCALES.includes(lang as string))
        );
    }

    clickPendingMedia(): Promise<boolean> | void {
        if (this.user!.waiting_pending_media!.length === 1) {
            this.router.navigate([
                `/${AppRoutesPaths.TREK}/${
                    this.user?.waiting_pending_media![0].trek_id
                }`
            ]);
        } else if (this.user!.waiting_pending_media!.length > 1) {
            return this.router.navigate([`/${AppRoutesPaths.PROFILE}`], {
                state: { pendingMedia: true }
            });
        }
    }

    openLoginModal(isMobile?: boolean) {
        if (isMobile) {
            this.sidenav
                .close()
                .then(() => this.loginDialogService.openDialog().subscribe());
        } else {
            this.loginDialogService.openDialog().subscribe();
        }
    }
}
