import { inject } from '@angular/core';
import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandlerFn,
    HttpInterceptorFn,
    HttpRequest
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SessionService } from '../../auth/services/session.service';
import * as Sentry from '@sentry/angular-ivy';
import { MatDialog } from '@angular/material/dialog';

export const appHttpInterceptor: HttpInterceptorFn = (
    req: HttpRequest<unknown>,
    next: HttpHandlerFn
): Observable<HttpEvent<unknown>> => {
    const sessionService = inject(SessionService);
    const matDialog = inject(MatDialog);
    if (req.headers.get('withCredentialsRemove')) {
        const headers = req.headers.delete('withCredentialsRemove');
        req = req.clone({ headers });
    } else {
        req = req.clone({
            withCredentials: true
        });
    }
    return next(req).pipe(
        catchError((error: HttpErrorResponse) => {
            Sentry.withScope(function (scope) {
                scope.setTag('scope', 'Http-failure');
                scope.setLevel('error');
                Sentry.captureException(error.error || error.message || error, {
                    contexts: {
                        'HTTP BODY': {
                            json: JSON.stringify(req.body || {})
                        }
                    }
                });
            });
            if (
                error.status === 401 &&
                !error.url?.includes('logout') &&
                !error.url?.includes('reg') &&
                error.url?.includes('wishtrip')
            ) {
                sessionService.clearSession();
                import(
                    './../../auth/components/modal-logged-out/modal-logged-out.component'
                ).then((c) => {
                    matDialog.open(c.ModalLoggedOutComponent, {
                        width: '370px',
                        minHeight: '219px'
                    });
                });
            }

            return throwError(() => error);
        })
    );
};
