{
  "name": "@recntrek/website",
  "version": "6.0.8",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "start-embedded-maps": "ng serve embedded",
    "build": "ng build --configuration production && source-map-explorer dist/*.js",
    "build-embedded-maps": "ng build embedded",
    "build-prod": "ng build --configuration production --deploy-url https://d144inbtkgi5lj.cloudfront.net/embedded/",
    "build-embedded-maps-prod": "ng build embedded --configuration production",
    "package-staging": "ng build --configuration='staging' --deploy-url https://d2mls2e113ft2a.cloudfront.net/ && ng build --configuration='staging' --deploy-url https://d2mls2e113ft2a.cloudfront.net/embedded/ embedded",
    "package-prod": "ng build --configuration production --deploy-url https://d144inbtkgi5lj.cloudfront.net/ && ng build --configuration production --deploy-url https://d144inbtkgi5lj.cloudfront.net/embedded/ embedded",
    "fix-ng-sw-path": "ngsw-config dist ./ngsw-config.json",
    "analyze": "ng build --configuration production --stats-json && webpack-bundle-analyzer dist/stats.json",
    "parse-poi": "python ./scripts/parse-poi.py ./src/poi-tag.json",
    "parse-locales": "node ./scripts/parse_site_languages",
    "lint-staged": "lint-staged",
    "prepare": "husky install && npm update"
  },
  "private": true,
  "dependencies": {
    "@angular-slider/ngx-slider": "^17.0.1",
    "@angular/animations": "^17.2.1",
    "@angular/cdk": "^17.2.0",
    "@angular/common": "^17.2.1",
    "@angular/compiler": "^17.2.1",
    "@angular/core": "^17.2.1",
    "@angular/forms": "^17.2.1",
    "@angular/material": "^17.2.0",
    "@angular/platform-browser": "^17.2.1",
    "@angular/platform-browser-dynamic": "^17.2.1",
    "@angular/router": "^17.2.1",
    "@angular/service-worker": "^17.2.1",
    "@mapbox/mapbox-gl-language": "^1.0.0",
    "@ng-idle/core": "^14.0.0",
    "@ngrx/effects": "^17.1.0",
    "@ngrx/store": "^17.1.0",
    "@ngx-translate/core": "^15.0.0",
    "@ngx-translate/http-loader": "^6.0.0",
    "@paypal/paypal-js": "^5.0.3",
    "@sentry/angular-ivy": "^7.54.0",
    "@turf/boolean-point-in-polygon": "^6.5.0",
    "@turf/distance": "^5.1.5",
    "angularx-qrcode": "^17.0.0",
    "chart.js": "^4.4.1",
    "date-fns": "^2.30.0",
    "file-saver-es": "^2.0.5",
    "keen-slider": "^6.8.6",
    "lodash-es": "^4.17.21",
    "mapbox-gl": "^3.0.0",
    "ng2-charts": "^5.0.4",
    "ngx-cookie-service": "^17.1.0",
    "ngx-image-cropper": "^7.1.2",
    "ngx-infinite-scroll": "^17.0.0",
    "ngx-quicklink": "^0.4.4",
    "quill": "^1.3.3",
    "redux": "4.0.1",
    "rxjs": "^7.8.0",
    "shufflejs": "^6.1.0",
    "simplify-js": "^1.2.4",
    "socket.io-client": "^2.2.0",
    "tslib": "^2.0.0",
    "video.js": "^8.10.0",
    "zone.js": "~0.14.2"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^17.0.8",
    "@angular-eslint/builder": "^17.1.1",
    "@angular-eslint/eslint-plugin": "^17.1.1",
    "@angular-eslint/eslint-plugin-template": "^17.1.1",
    "@angular-eslint/schematics": "^17.1.1",
    "@angular-eslint/template-parser": "^17.1.1",
    "@angular/cli": "^17.0.8",
    "@angular/compiler-cli": "^17.0.8",
    "@ngrx/store-devtools": "^17.0.1",
    "@types/file-saver-es": "^2.0.3",
    "@types/lodash-es": "^4.17.12",
    "@types/mapbox-gl": "^2.0.0",
    "@types/node": "^20.10.5",
    "@types/qrcode": "^1.5.5",
    "@types/socket.io-client": "^1.4.31",
    "@typescript-eslint/eslint-plugin": "^6.10.0",
    "@typescript-eslint/parser": "^6.10.0",
    "cachefactory": "^3.0.0",
    "codelyzer": "^6.0.0",
    "eslint": "^8.56.0",
    "eslint-plugin-import": "^2.26.0",
    "eslint-plugin-jsdoc": "^48.1.0",
    "eslint-plugin-prefer-arrow": "1.2.2",
    "husky": "^8.0.2",
    "lint-staged": "^15.2.0",
    "prettier": "latest",
    "source-map-explorer": "^2.5.3",
    "ts-node": "~10.9.2",
    "typescript": "~5.2.2"
  }
}
