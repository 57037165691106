<nav>
    <div class="navbar-section icon">
        <a
            [routerLink]="['/', HomeRoutesPaths.HOME]"
            class="navbar-logo">
            <img
                [ngSrc]="imgLogo"
                alt="logo"
                [height]="isScreen768 ? 28 : 49"
                id="header-logo"
                [width]="isScreen768 ? 31 : 170" />
        </a>
    </div>
    <div class="navbar-section center-navbar">
        <app-header-search-input></app-header-search-input>
        @defer (when !isScreen768) {
            <app-header-links></app-header-links>
        }
    </div>

    @defer (when !isScreen768) {
        <app-header-user-links
            (clickPendingMedia)="clickPendingMedia()"
            (openLoginModal)="openLoginModal()"
            [userState]="(userState$ | async)!"
            class="navbar-section navbar-actions"></app-header-user-links>
    }
    @defer (when isScreen768) {
        <app-sidenav
            (clickPendingMedia)="clickPendingMedia()"
            (onClickLogin)="openLoginModal()"
            [userState]="(userState$ | async)!"></app-sidenav>
    }
</nav>
