import {
    APP_INITIALIZER,
    enableProdMode,
    importProvidersFrom,
    inject,
    isDevMode
} from '@angular/core';
import { environment } from '@env';
import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from './app/app/app.component';
import * as Sentry from '@sentry/angular-ivy';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import {
    provideRouter,
    Router,
    withComponentInputBinding,
    withInMemoryScrolling,
    withPreloading
} from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import {
    HttpClient,
    provideHttpClient,
    withInterceptors
} from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import { reducers } from './app/app/store/app.reducers';
import { appRoutes, AppRoutesPaths } from './app/app/app-routes';
import { QuicklinkStrategy } from 'ngx-quicklink';
import { provideAnimations } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import { UserEffects } from './app/app/store/effects/user.effects';
import { WishListEffects } from './app/wish-list/store/wish-list.effects';
import { AppStoreEffect } from './app/app/store/effects/app-store.effect';
import { provideServiceWorker } from '@angular/service-worker';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { appHttpInterceptor } from './app/app/services/http-interceptor.service';
import { Observable } from 'rxjs';
import { AppLoadService } from './app/app/services/app-load.service';
import { AuthLoadService } from './app/auth/services/auth.load.service';
import { CookieService } from 'ngx-cookie-service';

if (environment.production) {
    enableProdMode();
}
const URLS_ALLOWED_AS_IFRAME: AppRoutesPaths[] = [
    AppRoutesPaths.SITE,
    AppRoutesPaths.ITINERARY,
    AppRoutesPaths.USER
];
const currentPath = location.pathname.split('/')[1] as AppRoutesPaths;

if (!top || top === self || URLS_ALLOWED_AS_IFRAME.includes(currentPath)) {
    bootstrapApplication(AppComponent, {
        providers: [
            CookieService,
            provideAnimations(),
            provideRouter(
                appRoutes,
                withInMemoryScrolling({ scrollPositionRestoration: 'top' }),
                withPreloading(QuicklinkStrategy),
                withComponentInputBinding()
            ),
            importProvidersFrom(StoreModule.forRoot(reducers)),
            importProvidersFrom(
                EffectsModule.forRoot([
                    UserEffects,
                    WishListEffects,
                    AppStoreEffect
                ])
            ),
            provideStoreDevtools(),
            provideHttpClient(withInterceptors([appHttpInterceptor])),
            importProvidersFrom(
                TranslateModule.forRoot({
                    loader: {
                        provide: TranslateLoader,
                        useClass: class CustomTranslateLoader
                            implements TranslateLoader
                        {
                            httpClient = inject(HttpClient);
                            getTranslation(lang: string): Observable<any> {
                                return this.httpClient.get(
                                    `${environment.ASSETS_BASE_URL}/strings/strings.${lang}.json`,
                                    {
                                        headers: {
                                            withCredentialsRemove: 'true'
                                        }
                                    }
                                );
                            }
                        },
                        deps: [HttpClient]
                    }
                })
            ),
            {
                provide: MAT_DIALOG_DEFAULT_OPTIONS,
                useValue: {
                    position: {
                        top: '10vh'
                    },
                    hasBackdrop: true,
                    panelClass: 'wt-dialog-cdk-overlay-pane',
                    closeOnNavigation: true
                }
            },
            {
                provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
                useValue: {
                    duration: 1500,
                    horizontalPosition: 'end',
                    verticalPosition: 'top',
                    panelClass: 'WT_snackbar'
                }
            },
            {
                provide: Sentry.TraceService,
                deps: [Router]
            },
            provideServiceWorker('ngsw-worker.js', {
                enabled: !isDevMode(),
                registrationStrategy: 'registerWhenStable:30000'
            }),
            {
                provide: APP_INITIALIZER,
                multi: true,
                deps: [AppLoadService, AuthLoadService],
                useFactory: (
                    appLoadService: AppLoadService,
                    authLoadService: AuthLoadService
                ) => {
                    authLoadService.initModule();
                    return () => {};
                }
            }
        ]
    }).catch((err) => console.error(err));
} else {
    self.document.body.style.display = 'none';
    top.location = self.location;
}
