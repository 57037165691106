import { Action, createReducer, on } from '@ngrx/store';
import { SiteCategories } from '../../../../typings/api';
import * as SiteActions from '../actions/site.actions';
import { LocaleType } from '../../models/locales.models';

export interface SiteState {
    siteLanguage: LocaleType | null;
    siteCategories: SiteCategories | null;
}

export const initialState: SiteState = {
    siteLanguage: null,
    siteCategories: null
};

const siteReducer = createReducer(
    initialState,
    on(SiteActions.setLanguage, (state, { language }) => ({
        ...state,
        siteLanguage: language
    })),
    on(SiteActions.setSiteCategorie, (state, { siteCategories }) => ({
        ...state,
        siteCategories
    }))
);

export function SiteReducer(state?: SiteState, action?: Action) {
    return siteReducer(state, action!);
}
