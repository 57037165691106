import { Action, createReducer, on } from '@ngrx/store';
import { Wishlist } from '../models/wishlist.model';
import * as WishListActions from './wish-list.actions';
import * as AuthActions from '../../auth/store/auth.actions';

export interface WishListState {
    wishList: Wishlist | null;
}

export const initialState: WishListState = {
    wishList: null
};

const wishListReducer = createReducer(
    initialState,
    on(AuthActions.logout, (state) => ({
        ...state,
        wishList: null
    })),
    on(WishListActions.setWishlist, (state, { wishList }) => ({
        ...state,
        wishList
    })),
    on(WishListActions.addSite, (state, { site_id }) => ({
        ...state,
        wishList: {
            ...state.wishList,
            sites: [...state.wishList?.sites!, site_id]
        }
    })),
    on(WishListActions.removeSite, (state, { site_id }) => ({
        ...state,
        wishList: {
            ...state.wishList,
            sites: [
                ...state.wishList!.sites!.filter((siteId) => siteId !== site_id)
            ]
        }
    })),
    on(WishListActions.addTrek, (state, { trek_id }) => ({
        ...state,
        wishList: {
            ...state.wishList,
            treks: [...state.wishList?.treks!, trek_id]
        }
    })),
    on(WishListActions.removeTrek, (state, { trek_id }) => ({
        ...state,
        wishList: {
            ...state.wishList,
            treks: [
                ...state.wishList!.treks!.filter((trekId) => trekId !== trek_id)
            ]
        }
    })),
    on(WishListActions.addItinerary, (state, { itinerary_id }) => ({
        ...state,
        wishList: {
            ...state.wishList,
            itinerary: [...state.wishList?.itinerary!, itinerary_id]
        }
    })),
    on(WishListActions.removeItinerary, (state, { itinerary_id }) => ({
        ...state,
        wishList: {
            ...state.wishList,
            itinerary: [
                ...state.wishList!.itinerary!.filter(
                    (itineraryId) => itineraryId !== itinerary_id
                )
            ]
        }
    }))
);

export function WishListReducer(state?: WishListState, action?: Action) {
    return wishListReducer(state, action!);
}
