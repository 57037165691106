import {
    afterNextRender,
    AfterRenderPhase,
    Component,
    inject,
    OnInit,
    signal
} from '@angular/core';
import * as UISelectors from './store/selectors/ui.selectors';
import { delay, Observable, shareReplay } from 'rxjs';
import { map } from 'rxjs/operators';
import {
    ActivatedRoute,
    Event,
    NavigationCancel,
    NavigationEnd,
    NavigationError,
    NavigationStart,
    Router,
    RouterEvent,
    RouterOutlet
} from '@angular/router';
import * as UIActions from './store/actions/ui.actions';
import { environment } from '@env';
import { KioskModeService } from '../itinerary/services/kiosk-mode.service';
import { WwbModeService } from './services/wwb-mode.service';
import { AppStoreService } from './store/app-store.service';
import { IS_SCREEN_425, IS_SCREEN_768 } from './constants/app.constants';
import { HeaderComponent } from './components/header/header.component';
import { NdSpinnersModule } from '../atoms/components/nd-spinners/nd-spinners.module';
import { AsyncPipe, NgClass } from '@angular/common';
import { FooterComponent } from './components/footer/footer.component';
import { BannerDownloadAppComponent } from './components/banner-download-app/banner-download-app.component';
import { AccessibilityIconComponent } from './components/accessibility-icon/accessibility-icon.component';
import { AppRoutesPaths } from './app-routes';
import { A11tPosition } from './components/accessibility-icon/accessibility-icon.component.consts';
import { AppLoadService } from './services/app-load.service';

@Component({
    selector: 'app-root',
    standalone: true,
    imports: [
        HeaderComponent,
        NdSpinnersModule,
        AsyncPipe,
        RouterOutlet,
        FooterComponent,
        BannerDownloadAppComponent,
        NgClass,
        AccessibilityIconComponent
    ],
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    displaySpinnerOverlay$!: Observable<boolean>;
    userEmbedWithoutHeader = signal(false);
    userEmbedWithoutFooter = signal(false);
    isWwb$ = this.wwbModeService.isWwbMode$;
    isKioskMode$!: Observable<boolean>;
    favIcon = document.querySelector('#appIcon') as HTMLLinkElement;
    displayDownloadBanner = true;
    downloadBannerToggle = true;
    isScreen425 = inject(IS_SCREEN_425);
    isScreen768 = inject(IS_SCREEN_768);
    DISPLAY_DOWNLOAD_BANNER_KEY = 'DISPLAY_DOWNLOAD_BANNER';
    a11yIconPosition = signal<A11tPosition>(A11tPosition.START_BOTTOM);

    constructor(
        private appstoreService: AppStoreService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private kioskModeService: KioskModeService,
        private wwbModeService: WwbModeService,
        private appLoadService: AppLoadService
    ) {
        afterNextRender(
            () => {
                appLoadService.initApp();
            },
            { phase: AfterRenderPhase.Read }
        );
    }

    ngOnInit(): void {
        this.favIcon.href = `${environment.ASSETS_BASE_URL}/favicon.ico`;
        this.initDisplaySpinnerOverlay();
        this.subscribeToRouterEvents();
        this.initDisplayDownloadBanner();
        this.initIsKioskMode();
    }

    initDisplayDownloadBanner() {
        this.displayDownloadBanner =
            !window.navigator.userAgent.includes('Prerender') &&
            !window.navigator.userAgent.includes('HeadlessChrome');
        const localStorageDisplayBanner: {
            value?: boolean;
            expiration?: Date;
        } = JSON.parse(
            localStorage.getItem(this.DISPLAY_DOWNLOAD_BANNER_KEY) || '{}'
        );
        this.downloadBannerToggle =
            localStorageDisplayBanner.value !== false ||
            (!!localStorageDisplayBanner.expiration &&
                new Date() > new Date(localStorageDisplayBanner.expiration));
    }

    setDisplayDownloadBanner(value: boolean) {
        const currentDate = new Date();
        currentDate.setHours(currentDate.getHours() + 1);
        localStorage.setItem(
            this.DISPLAY_DOWNLOAD_BANNER_KEY,
            JSON.stringify({ value, expiration: currentDate })
        );
        this.initDisplayDownloadBanner();
    }

    initDisplaySpinnerOverlay() {
        this.displaySpinnerOverlay$ = this.appstoreService.store
            .select(UISelectors.selectUiStateFeature)
            .pipe(
                map((uiState) => uiState.show_global_spinner),
                delay(0)
            );
    }

    subscribeToRouterEvents() {
        this.router.events.subscribe((event: Event) => {
            switch (true) {
                case event instanceof NavigationStart:
                    this.appstoreService.store.dispatch(
                        UIActions.toggleGlobalSpinner({ toggle: true })
                    );
                    break;

                case event instanceof NavigationEnd:
                case event instanceof NavigationCancel:
                case event instanceof NavigationError:
                    this.initA11yIconPosition((event as RouterEvent).url);
                    this.appstoreService.store.dispatch(
                        UIActions.toggleGlobalSpinner({ toggle: false })
                    );
                    if (this.router.url.includes(AppRoutesPaths.USER)) {
                        const embedParam =
                            this.activatedRoute.snapshot.queryParamMap.get(
                                'embed'
                            );
                        if (embedParam) {
                            const [showHeader, showFooter] =
                                embedParam.split('');
                            this.userEmbedWithoutHeader.set(showHeader === '0');
                            this.userEmbedWithoutFooter.set(showFooter === '0');
                        }
                    }
                    break;

                default:
                    break;
            }
        });
    }
    initIsKioskMode() {
        this.isKioskMode$ =
            this.kioskModeService.isKioskMode$.pipe(shareReplay());
    }

    initA11yIconPosition(url: string) {
        let position: A11tPosition = A11tPosition.START_BOTTOM;
        if (url.includes(AppRoutesPaths.SITE) && this.isScreen768) {
            position = A11tPosition.START_TOP;
        }
        this.a11yIconPosition.set(position);
    }
}
