import { Action, createReducer, on } from '@ngrx/store';
import {
    defaultFilterState,
    resetFilterType
} from '../../app/store/app-store.constants';
import {
    typeFilterAreas,
    typeFilterLabels,
    typeFilterSegments,
    typeFilterTags
} from '../components/search-treks-filter/search-treks-filter';
import { logout } from '../../auth/store/auth.actions';
import { syncQuery, updateFilter, updateSelectedTab } from './search.actions';
import { cloneDeep } from 'lodash-es';

export interface SearchState {
    query: string;
    filter: any;
    tab?: number;
}

export const initialState: SearchState = {
    query: '',
    filter: defaultFilterState,
    tab: undefined
};

const searchReducer = createReducer(
    initialState,
    on(syncQuery, (state, { query }) => ({
        ...state,
        query
    })),
    on(updateFilter, (state, { id, filter_type, value }) => {
        if (filter_type === resetFilterType) {
            return {
                ...state,
                filter: defaultFilterState
            };
        }

        // Determine filter type
        const isButton =
            [typeFilterAreas, typeFilterSegments].indexOf(filter_type) >= 0;
        const isCheckbox = filter_type === typeFilterTags;
        const isLabel = filter_type === typeFilterLabels;
        const newFilter = cloneDeep(state.filter);

        if (isButton || isCheckbox || isLabel) {
            if (value) {
                newFilter[filter_type].push(id);
            } else {
                const index = newFilter[filter_type].indexOf(id);
                newFilter[filter_type].splice(index, 1);
            }
        } else {
            newFilter[filter_type] = {
                model: (value as [number, number])[0],
                modelMax: (value as [number, number])[1]
            };
        }

        return {
            ...state,
            filter: newFilter
        };
    }),
    on(updateSelectedTab, (state, { tab_num }) => ({
        ...state,
        tab: tab_num
    })),
    on(logout, () => initialState)
);

export function SearchReducer(state?: SearchState, action?: Action) {
    return searchReducer(state, action!);
}
