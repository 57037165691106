import { environment } from '@env';
import {
    DestroyRef,
    inject,
    Injectable,
    Renderer2,
    RendererFactory2
} from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import {
    DEFAULT_LOCALE,
    LOCALSTORAGE_LANG_KEY,
    RTL_LOCALES,
    SUPPORTED_LANGS
} from '../constants/locales.constants';
import { AppState } from '../store/app.reducers';
import * as SiteActions from '../store/actions/site.actions';
import { NavigationEnd, Router } from '@angular/router';
import { filter, map, skip, take } from 'rxjs/operators';
import { lastValueFrom } from 'rxjs';
import { SharedService } from './shared.service';
import packageJson from './.././../../../package.json';
import * as Sentry from '@sentry/angular-ivy';
import { LocaleType } from '../models/locales.models';
import { FB_APP_ID } from '../../auth/constants/auth.constants';
import { AppStoreService } from '../store/app-store.service';
import { setRTLTextPlugin } from 'mapbox-gl';
import { SwUpdate } from '@angular/service-worker';

@Injectable({ providedIn: 'root' })
export class AppLoadService {
    destroyRef = inject(DestroyRef);
    private renderer: Renderer2;

    constructor(
        private translateService: TranslateService,
        private appStoreService: AppStoreService,
        private rendererFactory: RendererFactory2,
        private router: Router,
        private sharedService: SharedService,
        private swUpdate: SwUpdate
    ) {
        this.renderer = this.rendererFactory.createRenderer(null, null);
        this.addPreconnectTags();
        this.configTranslation();
        this.initSiteCategories();
    }

    initApp() {
        this.setMapboxRtlTextPlugin();
        this.initSentry();
        this.initHubspot();
        this.initFacebookSnippet();
        this.fireRoutingEvents();
        this.initGoogleSigninModule();
        this.initFacebookSigninModule();
        this.initGoogleTagManager();
        this.swUpdate.versionUpdates.subscribe((e) => {
            window.location.reload();
        });
    }

    configTranslation() {
        this.translateService.setDefaultLang(DEFAULT_LOCALE);
        this.translateService.langs = SUPPORTED_LANGS;
        const userLang =
            localStorage.getItem(LOCALSTORAGE_LANG_KEY) ||
            this.translateService.getBrowserLang();
        const lang = (
            SUPPORTED_LANGS.includes(userLang as LocaleType)
                ? userLang!
                : DEFAULT_LOCALE
        ) as LocaleType;
        this.translateService.use(lang);
        this.appStoreService.store.dispatch(
            SiteActions.setLanguage({ language: lang })
        );
        if (RTL_LOCALES.indexOf(lang) >= 0) {
            this.renderer.addClass(document.body, 'rtl');
        } else {
            this.renderer.removeClass(document.body, 'rtl');
        }
        this.translateService.onLangChange
            .pipe(skip(1))
            .subscribe((event: LangChangeEvent) => {
                localStorage.setItem(LOCALSTORAGE_LANG_KEY, event.lang);
                window.location.reload();
            });
    }

    setMapboxRtlTextPlugin() {
        setRTLTextPlugin(
            'https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-rtl-text/v0.2.1/mapbox-gl-rtl-text.js',
            (error: any) => console.log('Failed to load RTL plugin', error)
        );
    }

    initSiteCategories(): void {
        const language =
            this.appStoreService.state.siteState.siteLanguage || DEFAULT_LOCALE;

        this.sharedService.getCategories(language).subscribe({
            next: (result) => {
                const categories: { [key: string]: any } = {};
                result.categories.forEach((cat) => {
                    categories[cat.id] = {
                        color: cat.color,
                        displayName: cat.displayName
                    };
                });

                this.appStoreService.store.dispatch(
                    SiteActions.setSiteCategorie({
                        siteCategories: categories
                    })
                );
            },
            error: () =>
                console.log('There was an error getting categories from list')
        });
    }

    initSentry(): void {
        if (!environment.dev) {
            Sentry.init({
                dsn: 'https://c9476a5f8cf34c9a9fc2659ea00faaa8@o1150503.ingest.sentry.io/6227547',
                tracesSampleRate: 0.1,
                environment: environment.production ? 'prd' : 'stg',
                release: `WT-B2C@${packageJson.version}`,
                sampleRate: 1,
                integrations: [new Sentry.BrowserTracing()],
                beforeSend: (event) =>
                    lastValueFrom(
                        this.appStoreService.store.pipe(
                            take(1),
                            map((appState: AppState) => {
                                event.tags = event.tags || {};
                                if (appState.userState.user?.id) {
                                    event.tags.userId =
                                        appState.userState.user.id;
                                }
                                return event;
                            })
                        )
                    ) as PromiseLike<any>,
                ignoreErrors: [
                    // Random plugins/extensions
                    'top.GLOBALS',
                    // See:
                    // http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
                    'originalCreateNotification',
                    'canvas.contentDocument',
                    'MyApp_RemoveAllHighlights',
                    'http://tt.epicplay.com',
                    "Can't find variable: ZiteReader",
                    'jigsaw is not defined',
                    'ComboSearch is not defined',
                    'http://loading.retry.widdit.com/',
                    'atomicFindClose',
                    // Facebook borked
                    'fb_xd_fragment',
                    // ISP "optimizing" proxy - `Cache-Control: no-transform`
                    // seems to reduce this. (thanks @acdha) See
                    // http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
                    'bmi_SafeAddOnload',
                    'EBCallBackMessageReceived',
                    // See
                    // http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
                    'conduitPage',
                    // Generic error code from errors outside the security
                    // sandbox You can delete this if using raven.js > 1.0,
                    // which ignores these automatically.
                    'Script error.',
                    // Avast extension error
                    '_avast_submit'
                ],
                denyUrls: [
                    // Google Adsense
                    /pagead\/js/i,
                    // Facebook flakiness
                    /graph\.facebook\.com/i,
                    // Facebook blocked
                    /connect\.facebook\.net\/en_US\/all\.js/i,
                    // Woopra flakiness
                    /eatdifferent\.com\.woopra-ns\.com/i,
                    /static\.woopra\.com\/js\/woopra\.js/i,
                    // Chrome extensions
                    /extensions\//i,
                    /^chrome:\/\//i,
                    // Other plugins
                    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
                    /webappstoolbarba\.texthelp\.com\//i,
                    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i
                ]
            });
        }
    }

    initHubspot(): void {
        if (environment.production) {
            (function (id, src) {
                if (document.getElementById(id)) {
                    return;
                }
                const js = document.createElement('script');
                js.src = src;
                js.type = 'text/javascript';
                js.id = id;
                const e = document.getElementsByTagName('script')[0];
                e.parentNode?.insertBefore(js, e);
            })(
                'hs-analytics',
                '//js.hs-analytics.net/analytics/1547038500000/4561053.js'
            );
        }
    }

    initFacebookSnippet(): void {
        if (environment.production) {
            // @ts-ignore
            !(function (f, b, e, v, n, t, s) {
                if (f.fbq) {
                    return;
                }
                // @ts-ignore
                n = f.fbq = function () {
                    // @ts-ignore
                    n.callMethod // @ts-ignore
                        ? n.callMethod.apply(n, arguments) // @ts-ignore
                        : n.queue.push(arguments);
                };
                if (!f._fbq) {
                    f._fbq = n;
                }
                // @ts-ignore
                n.push = n;
                // @ts-ignore
                n.loaded = !0;
                // @ts-ignore
                n.version = '2.0';
                // @ts-ignore
                n.queue = [];
                // @ts-ignore
                t = b.createElement(e);
                // @ts-ignore
                t.async = !0;
                // @ts-ignore
                t.src = v;
                // @ts-ignore
                s = b.getElementsByTagName(e)[0];
                // @ts-ignore
                s.parentNode.insertBefore(t, s);
            })(
                window,
                document,
                'script',
                'https://connect.facebook.net/en_US/fbevents.js'
            );
            window.fbq('init', '350356792924574');
        }
    }

    fireRoutingEvents(): void {
        this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe((event) => {
                // hubspot event
                if (environment.production) {
                    const _hsq = (window._hsq = window._hsq || []);
                    _hsq.push(['setPath', location.pathname]);
                    _hsq.push(['trackPageView']);

                    if (window.fbq) {
                        window.fbq('track', 'PageView');
                    }
                }
                // Google Tag manager virtualPageview Event
                (window as any).dataLayer?.push({
                    event: 'virtualPageview',
                    pageUrl: (event as NavigationEnd).url,
                    pageTitle: document.title
                });
            });
    }

    initGoogleTagManager(): void {
        const script = document.createElement('script');
        script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${environment.GTM_CONTAINER_ID}');`;
        const head = document.getElementsByTagName('head')[0];
        head.appendChild(script);
        const noscript = document.createElement('noscript');
        noscript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=${environment.GTM_CONTAINER_ID}"height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
        document.body.appendChild(noscript);
    }

    initGoogleSigninModule(): void {
        const script = document.createElement('script');
        script.src = 'https://accounts.google.com/gsi/client';
        script.async = true;
        script.defer = true;
        document.body.appendChild(script);
    }

    initFacebookSigninModule() {
        const id = 'facebook-jssdk';
        const ref = document.getElementsByTagName('script')[0];
        if (document.getElementById(id)) {
            return;
        }
        const js = document.createElement('script');
        js.id = id;
        js.async = true;
        js.src = `https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v2.12&appId=${FB_APP_ID}`;
        ref.parentNode?.insertBefore(js, ref);
    }

    addPreconnectTags() {
        const linkElement1 = document.createElement('link');
        linkElement1.rel = 'preconnect';
        linkElement1.href = environment.SITE_MEDIA_URL;
        document.head.appendChild(linkElement1);
        const linkElement2 = document.createElement('link');
        linkElement2.rel = 'preconnect';
        linkElement2.href = 'https://s3.amazonaws.com/';
        document.head.appendChild(linkElement2);
    }
}
