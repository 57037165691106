@if (
    !userEmbedWithoutHeader() && !(isKioskMode$ | async) && !(isWwb$ | async)
) {
    <app-header></app-header>
}
<app-nd-spinner-overlay
    [toggle]="!!(displaySpinnerOverlay$ | async)"
    containerSelector="body"
    fixed="true"
    timeout>
</app-nd-spinner-overlay>
<main
    class="main"
    [ngClass]="{ 'kiosk-mode': isKioskMode$ | async }">
    <router-outlet></router-outlet>
</main>
@defer (when !isScreen768) {
    @if (!userEmbedWithoutFooter() && !(isKioskMode$ | async)) {
        <app-footer></app-footer>
    }
}

@defer (when isScreen425 && displayDownloadBanner) {
    @if (downloadBannerToggle) {
        <app-banner-download-app (dismiss)="setDisplayDownloadBanner(false)">
        </app-banner-download-app>
    }
}
<app-accessibility-icon
    [position]="a11yIconPosition()"></app-accessibility-icon>
