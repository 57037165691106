import { Action, createReducer, on } from '@ngrx/store';
import * as UIActions from '../actions/ui.actions';

export interface UIState {
    show_login: boolean;
    show_global_spinner: boolean;
}

export const initialState: UIState = {
    show_login: false,
    show_global_spinner: false
};

const uiReducer = createReducer(
    initialState,
    on(UIActions.toggleGlobalSpinner, (state, { toggle }) => ({
        ...state,
        show_global_spinner: toggle
    }))
);

export function UIReducer(state?: UIState, action?: Action) {
    return uiReducer(state, action!);
}
